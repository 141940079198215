<template>
  <div id="box">
    <el-container>
      <el-header>
        <h1>质量标准法规</h1>
      </el-header>
      <el-main>
        <el-table :data="tableData" style="width: 100%" :show-header="status">
          <el-table-column prop="date" label="日期" width="480">
            <template slot-scope="scope">
              <i class="el-icon-collection"></i>
              <span style="margin-left: 10px">{{ scope.row.date }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="filename" label="姓名" width="380">
            <template slot-scope="scope">
              <i class="el-icon-collection-tag"></i>
              <span style="margin-left: 10px">{{ scope.row.filename }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="starttime" label="地址">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 10px">{{ scope.row.starttime }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer></el-footer>
    </el-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: false,
      tableData: [
        {
          date: "《绿色印刷 转移接装纸印制过程控制要求》",
          filename: "文件编号：CY/T 250—2021",
          starttime: "颁布日期： 2021-11-01",
        },
        {
          date: "《印刷类柔性透明薄膜电子器件质量要求》",
          filename: "文件编号：CY/T 248—2021",
          starttime: "颁布日期：2021-11-01",
        },
        {
          date: "《印刷产品智能设计与仿真指南》",
          filename: "文件编号：CY/T 245—2021",
          starttime: "颁布日期：2021-11-01",
        },
        {
          date: "《印刷智能工厂构建规范》",
          filename: "文件编号：CY/T 243—2021",
          starttime: "颁布日期：2021-11-01",
        },
        {
          date: "《产品质量法》",
          filename: "文件编号：",
          starttime: "颁布日期：2018年12月29日",
        },
        {
          date: "《中华人民共和国消费者权益保护法》",
          filename: "文件编号：",
          starttime: "颁布日期：2013年10月25日",
        },
        {
          date: "《纸与纸板水分测定法》",
          filename: "文件编号：GB/T 462-2008",
          starttime: "颁布日期：2008年08月19日",
        },
        {
          date: "《中华人民共和国国家标准 瓦楞纸板耐破强》",
          filename: "文件编号：GB/T 6548-1998",
          starttime: "颁布日期：1998年10月25日",
        },
        {
          date: "《平版印刷工国家职业标准》",
          filename: "文件编号：",
          starttime: "颁布日期：2008年04月08日",
        },
        {
          date: "《绿色印刷 产品抽样方法及测试部位确定原则》",
          filename: "文件编号：CY/T 131—2015",
          starttime: "颁布日期：2015年",
        },
        {
          date: "《绿色印刷 第1部分：阅读类印刷品》",
          filename: "文件编号：CY/T 132.1—2015",
          starttime: "颁布日期：2015年",
        },
        {
          date: "《绿色印刷 通用技术要求与评价方法 平版印刷》",
          filename: "文件编号：CY/T 130.1—2015",
          starttime: "颁布日期：2015年",
        },

      ],
    };
  },
};
</script>
<style  scoped>
* {
  margin: 0;
  padding: 0;
}
.el-header {
  background-color: #b3c0d1;
  color: #ccc;
  text-align: center;
  line-height: 60px;
}
body > .el-container {
  margin-bottom: 40px;
}
.el-main {
  margin-top: 20px;
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  height: 600px;
  /* border: 1px solid red; */
  /* line-height: 500px; */
}
.el-header {
  background-image: url("../../assets/img/zhengce.png");
}

/* header > h1 {
  color: #fff;
  line-height: 60px;
  font-size: 30px !important;
}  */
</style>>
